import React from "react";

export const Partners = (props) => {
  return (
    <div id="partners" className="text-center">
      <div className="container">
      <div className=" section-title">
          <h2 id="part">Partners we have worked with</h2>
        </div>
        <div id="support" className="d-flex mb-6">
          {props.data
            ? props.data.map((d, i) => (
                <>
                  <img key={`${d.title}-${i}`} src={d.image} alt="" height='50px'  className="d-block px-4"/>
                </>
              ))
            : "Loading..."}
        </div>
      </div>
    </div>
  );
};
